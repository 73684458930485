import React, { useEffect, useRef }from "react"
import { GOOGLE_CLIENT_ID } from "../../constants/nextEnv"
import Head from "next/head"
import { GOOGLE_RED_COLOR } from "../../constants/style";
import Button from "../interact/Button";

const SOCIAL_BUTTON_WIDTH = '130px';

export default function GoogleSignIn({
  children,
  i18n,
  signInBtn,
  googleLoading,
  setGoogleLoading,
  signInSuccess
}) {
  const googleAuthRef = useRef(null);
  useEffect(()=>{
    if (signInBtn=== null) return;
    window.onGoogleLibraryLoad = ()=>{
      /**
       * initTokenClient api
       * https://developers.google.com/identity/oauth2/web/guides/use-token-model
       */
      // eslint-disable-next-line no-undef
      const client = google.accounts.oauth2.initTokenClient({
        callback: (response) => {
          /**
           * Cannot catch errors on requestAccessToken UI issues or user abort
           * https://github.com/google/google-api-javascript-client/issues/816
           */
          setGoogleLoading(true)
          signInSuccess(response)
        },
        client_id: GOOGLE_CLIENT_ID,
        scope: 'profile',
      });
      googleAuthRef.current = client;
    }
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInBtn])

  return (
    <>
      <Head>
        <script async defer onload="onGoogleLibraryLoad()" src="https://accounts.google.com/gsi/client" ></script>
      </Head>
      <Button
        bgColor={GOOGLE_RED_COLOR}
        borderRadius='21px'
        color='#fff'
        isLoading={googleLoading}
        lineHeight={1}
        onClick={(event)=>{
          if (googleAuthRef.current) {
            googleAuthRef.current.requestAccessToken();
          }
        }}
        type='button'
        width={SOCIAL_BUTTON_WIDTH}
      >
        {`${i18n['GOOGLE']}`}
      </Button>
    </>
  )
}
