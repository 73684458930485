import styled from "styled-components"
import { HIGHLIGHT_COLOR, MEDIA_QUERY } from "../../../constants/style"
import { useState } from "react"
import LoginForm from "../LoginForm"
import SignupForm from "../SignupForm"

const RootWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  @media ${MEDIA_QUERY.mobileL} {
    width: 500px;
  }
`

const SwitchWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 15px;
  @media ${MEDIA_QUERY.mobileL} {
    right: 90px;
  }
`

const TextWrapper = styled.span`
  cursor: pointer;
  color: ${(props) => props.selected ? HIGHLIGHT_COLOR : '#000'};
  font-size: 16px;
`
const DividerWrapper = styled.span`
  margin: 0 10px;
`

const FormWrapper = styled.div`
  padding: 30px 30px 10px 30px;
  @media ${MEDIA_QUERY.mobileL} {
    padding: 60px 90px;
  }
`

export default function LoginSignupForm({ i18n, loginProps = {}, signupProps = {} }) {
  // UI is either login or signup
  const [ui, setUI] = useState('login')
  const [ error, setError ] = useState('')
  return (
    <RootWrapper>
      <SwitchWrapper>
        <TextWrapper
          onClick={() => setUI('login')}
          selected={ui === 'login'}
        >
          {i18n['LOGIN']}
        </TextWrapper>
        <DividerWrapper>
          <span> | </span>
        </DividerWrapper>
        <TextWrapper
          onClick={() => setUI('signup')}
          selected={ui === 'signup'}
        >
          {i18n['SIGNUP']}
        </TextWrapper>
      </SwitchWrapper>
      <FormWrapper>
        {
          ui === 'login' ?
          <LoginForm {...loginProps} error={error} i18n={i18n} setError={setError} /> :
          <SignupForm {...signupProps} i18n={i18n} />
        }
      </FormWrapper>
    </RootWrapper>
  ) 
}
