import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  &:after, &:before {
    content: '';
    flex: 1;
    border-bottom: 1px solid #979797;
  }

  &:after {
    margin-left: .25em;
  }

  &:before {
    margin-right: .25em;
  }
`

const TextWrapper = styled.span`
  font-size: ${(props) => props.styles.fontSize || '20px'};
  text-align: center;
  color: #000000;
`

export default function Separator(props) {
  const { styles = {}, text } = props
  return <Wrapper><TextWrapper styles={styles}>{text}</TextWrapper></Wrapper>
}
