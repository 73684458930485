import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { EMAIL, FACEBOOK, GOOGLE } from '../../constants/misc'
import Formask from 'formask'
import styled from 'styled-components'
import SvgMail from '../icons/Mail'
import SvgPassword from '../icons/Password'
import Input from '../interact/Input'
import ErrorWrapper from '../style/ErrorWrapper'
import InputWrapper from '../style/InputWrapper'
import { login } from '../../services/next/login'
import { user } from '../../helpers/clientStorage'
import { useRouter } from 'next/router'
import Button from '../interact/Button'
import { NAVIGATION_HIGHLIGHT_COLOR, FB_BLUE_COLOR, BUTTON_STYLE_MAIN } from '../../constants/style'
import Separator from '../common/Separator'
import Head from 'next/head'
import Link from 'next/link'
import GoogleSignIn from '../common/GoogleSignIn'

const SOCIAL_BUTTON_WIDTH = '130px';
const GOOGLE_BTN_ID = 'google-signin-btn';

const ButtonWrapper = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
`

const SocialButtonWrapper = styled.div`
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
`

const FormWrapper = styled.form`
`

const FieldWrapper = styled.div`
  margin: 0 0 20px 0;
  ${InputWrapper} {
    margin: 10px 0;
    padding: 10px 0;
  }
`;

const SocialLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
`


const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const ForgetPasswordWrapper = styled.div`
  font-size: 14px;
  right: 0;
  top: 40px;
  position: absolute;
`

const LinkWrapper = styled.a`
  color: ${NAVIGATION_HIGHLIGHT_COLOR};
`

const onSubmit = async (values, formaskProps, {i18n, isModal, onLoginSuccess, router, setError}) => {
  const { setIsSubmitting } = formaskProps
  const body = {
    ...values,
    type: EMAIL
  }
  if (!body.password || !body.email  ) {
    setError(i18n["EMAIL_AND_PASSWORD_REQUIRED"])
    setIsSubmitting(false)
    return;
  }

  try {
    const res = await login(body)
    if(res.error) {
      console.log(res.error)
      setError(res.error);
      setIsSubmitting(false)
      return
    }
    user.set(res.data)
    const { from } = queryString.parse(window.location.search) || {}
    if (!isModal) { router.replace(from ? from : '/') }
    onLoginSuccess()
  } catch(e) {
    return;
  }
  
}

const onFBLogin = async ({isModal, onLoginSuccess, router, setError, setFBLoading}) => {
  const callback = async (response) => {
    if (response.status === 'connected') {
      const {authResponse = {}} = response;
      const { accessToken, userID } = authResponse
      try {
        const res = await login({fbAccessToken: accessToken, type: FACEBOOK, userID})
        if(res.error) {
          setError(res.error);
          return
        }
        user.set({
          fbToken: accessToken,
          ...res.data,
        })
        const { from } = queryString.parse(window.location.search) || {}
        if (!isModal) { router.replace(from ? from : '/') }
        onLoginSuccess()
      } catch(e) {
        return;
      } finally {
        setFBLoading(false);
      }
      return true
    } else {
      setFBLoading(false);
      console.log(response);
    }
  }
  // eslint-disable-next-line
  FB.login(async function(response) {
    callback(response);
  }, {scope: 'public_profile,email'});
}

async function googleSignInSuccess({
  setError,
  response = {},
  isModal,
  router,
  onLoginSuccess,
  setGoogleLoading
}){
  const {access_token: id_token} = response; 

  try {
    const res = await login({googleAccessToken: id_token, type: GOOGLE})
    if(res.error) {
      setError(res.error);
      return
    }
    user.set({
      googleToken: id_token,
      ...res.data,
    })
    const { from } = queryString.parse(window.location.search) || {}
    if (!isModal) { router.replace(from ? from : '/') }
    onLoginSuccess()
  } catch(e) {
    return;
  } finally {
    setGoogleLoading(false);
  }
}


export default function LoginForm({ error, i18n, isModal = false, onLoginSuccess, setError }) {
  const router = useRouter()
  const [ fbLoading, setFBLoading ] = useState(false)
  const [ googleLoading, setGoogleLoading] = useState(false)

  useEffect(() => {
    const { error, id_token } = queryString.parse(window.location.hash) || {}
    async function finishGoolgeLogin() {
      try {
        const res = await login({googleAccessToken: id_token, type: GOOGLE})
        if(res.error) {
          setError(res.error);
          return
        }
        user.set({
          googleToken: id_token,
          ...res.data,
        })
        const { from } = queryString.parse(window.location.search) || {}
        if (!isModal) { router.replace(from ? from : '/') }
        onLoginSuccess()
      } catch(e) {
        return;
      } finally {
        setGoogleLoading(false);
      }
    }
    if (id_token) {
      setGoogleLoading(true);
      finishGoolgeLogin();
    }
    if(error) {
      setError(error);
      return
    }
  }, [isModal, onLoginSuccess, router, setError])

  return (
    <>
      <Head>
        <script src='/js/initFB.js' type='text/javascript'></script>
        {/* if login have to be localized. Neeed to know user locale by http request */}
        <script async defer crossorigin="anonymous" nonce="w68sSeDw" src="https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v10.0&appId=3149567761771415&autoLogAppEvents=1"></script>
      </Head>
      <div id="fb-root"></div>
      <Formask
        onSubmit={(values, formaskProps) => onSubmit(values, formaskProps, {i18n, isModal, onLoginSuccess,router, setError})}
        render={formaskProps => {
          const { hook, isSubmitting, submitHandler } = formaskProps;
          return (
            <>
              <FieldWrapper>
                <FormWrapper
                  onSubmit={submitHandler}
                >
                  <InputWrapper >
                    <label>
                      <SvgMail />
                    </label>
                    {
                      hook('email')(
                        <Input
                          FieldWrapper={InputFieldWrapper}
                          bgColor="#fff"
                          placeholder={i18n['EMAIL']}
                          type="email"
                          width="100%"
                        />
                      )
                    }
                  </InputWrapper>
                  <InputWrapper>
                    <label>
                      <SvgPassword />
                    </label>
                    {
                      hook('password')(
                        <Input
                          FieldWrapper={InputFieldWrapper}
                          bgColor="#fff"
                          placeholder={i18n['PASSWORD']}
                          type='password'
                          width="100%"
                        />
                      )
                    }
                    <ForgetPasswordWrapper>
                      <Link passHref href='/user/forgetpassword'>
                        <LinkWrapper>
                          {i18n['FORGET_PASSWORD']}
                        </LinkWrapper>
                      </Link>
                    </ForgetPasswordWrapper>
                  </InputWrapper>
                  <ErrorWrapper>{error}</ErrorWrapper>
                  <ButtonWrapper>
                    <Button
                      {...BUTTON_STYLE_MAIN}
                      isLoading={isSubmitting}
                      type='submit'
                    >
                      {i18n['LOGIN']}
                    </Button>
                  </ButtonWrapper>
                </FormWrapper>
              </FieldWrapper>
              <Separator
                styles={{ fontSize: '14px', marginTop: '30px' }}
                text={i18n['CONNECT_WITH_SOCIAL']}
              />
              <SocialLoginWrapper>
                <SocialButtonWrapper>
                  <Button
                    bgColor={FB_BLUE_COLOR}
                    borderRadius='21px'
                    color='#fff'
                    isLoading={fbLoading}
                    lineHeight={1}
                    onClick={(event) => {
                      event.preventDefault()
                      setFBLoading(true)
                      onFBLogin({ isModal, onLoginSuccess, router, setError, setFBLoading })
                    }}
                    type='button'
                    width={SOCIAL_BUTTON_WIDTH}
                  >
                    {`${i18n['FACEBOOK']}`}
                  </Button>
                  <GoogleSignIn
                    googleLoading={googleLoading}
                    i18n={i18n}
                    setGoogleLoading={setGoogleLoading}
                    signInBtn={GOOGLE_BTN_ID}
                    signInSuccess={(response)=>{
                      googleSignInSuccess({
                        isModal,
                        onLoginSuccess,
                        response,
                        router,
                        setError,
                        setGoogleLoading
                      })
                    }}
                  />
                </SocialButtonWrapper>
              </SocialLoginWrapper>
            </>
          )
        }}
      />
    </>
  )
}

const noopFunc = () => {}
LoginForm.defaultProps = {
  onLoginSuccess: noopFunc
}
